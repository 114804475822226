<template>
  <div>
    <d-body-top-bar :title="$t('views.playground.index.parameters.title')">
      <div slot="tree">
        <label class="inactive-tree "> {{ $t('views.playground.activities-area') }}</label> /
        <label class="bg-doinsport-primary ml-1"> {{ $t('views.playground.index.parameters.title') }}</label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <options/>
      <restrictions/>
      <forms/>
      <block-price-categories/>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Forms: () => import('./forms/Index'),
    Options: () => import('./options/Index'),
    Restrictions: () => import('./restrictions/Index'),
    BlockPriceCategories: () => import('./block-price-categories/Index'),
  }
}
</script>
